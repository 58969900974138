<!--
 * @Author: your name
 * @Date: 2020-12-22 14:43:51
 * @LastEditTime: 2022-01-20 17:08:33
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ids-admin-web的副本/src/views/enterprise/index.vue
-->
<template>
  <div class="agree">
    <div class="agree__top">
      <div>用户协议</div>
    </div>
    <div class="agree__body">
      <p class="agree__body__top">方正电子学术期刊产品用户协议</p>
      <p class="agree__body__prompt__title">特别提示：</p>
      <p class="agree__body__prompt__body">
        您在使用方正电子有限责任公司提供的各项服务之前，请您务必审慎阅读、充分理解本协议各条款内容，特别是以粗体标注的部分，包括但不限于免除或者限制责任的条款。如您不同意本服务协议及/或随时对其的修改，您可以主动停止使用方正电子提供的服务；您一旦使用方正电子提供的服务，即视为您已了解并完全同意本服务协议各项内容，包括方正电子有限责任公司对服务协议随时所做的任何修改，并成为我们的用户。
      </p>
      <div class="agree__body__detail">
        <p class="agree__body__detail--title">一、总则</p>
        <div class="agree__body__detail--body">
          <p>
            1.用户可以使用方正电子学术期刊产品的各类单项服务，当用户使用方正电子各单项服务时，用户的使用行为视为其对该单项服务的服务条款以及方正电子在该单项服务中发出的各类公告的同意。
          </p>
          <p>
            2.方正电子学术期刊产品用户协议以及各类单项服务条款和公告可由方正电子公司随时更新，且无需另行通知。您在使用相关服务时,应关注并遵守其所适用的相关条款。
          </p>
        </div>
      </div>
      <div class="agree__body__detail">
        <p class="agree__body__detail--title">二、帐号注册与使用</p>
        <div class="agree__body__detail--body">
          您使用部分方正电子学术期刊产品或服务时需要注册方正电子学术期刊帐号，当您注册和使用方正电子学术期刊帐号时应遵守下述要求：
          <p>
            1.用户注册成功后，方正电子公司将给予每个用户一个用户帐号，用户可以自主设置帐号密码。该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。
          </p>
          <p>
            2.您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，除方正电子学术期刊平台的具体产品对帐户有单独的注册要求外，您可获得方正电子学术期刊帐号并成为方正电子学术期刊用户，可通过方正电子学术期刊帐户使用方正电子学术期刊平台的各项产品和服务。
          </p>
          <p>
            3.为了方便您在方正电子学术期刊产品中享有一致性的服务，如您已经在某一方正电子学术期刊产品中登录方正电子学术期刊帐号，在您首次使用其他方正电子学术期刊产品时可能同步您的登录状态。此环节并不会额外收集、使用您的个人信息。如您想退出帐号登录，可在产品设置页面退出登录。
          </p>
          <p>
            4.方正电子学术期刊帐号（即方正用户ID）的所有权归方正电子有限公司，用户按注册页面引导填写信息，阅读并同意本协议且完成全部注册程序后，即可获得方正电子学术期刊帐号并成为用户。用户应提供及时、详尽及准确的个人资料，并不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。如果因注册信息不真实或更新不及时而引发的相关问题，方正电子有限公司不负任何责任。您可以通过方正电子学术期刊帐号设置页面查询、更正您的信息，方正电子学术期刊帐号设置：用户应当通过真实身份信息认证注册帐号，且用户提交的帐号名称、头像和简介等注册信息中不得出现违法和不良信息，经方正电子有限公司审核，如存在上述情况，方正电子有限公司将不予注册；同时，在注册后，如发现用户以虚假信息骗取帐号名称注册，或其帐号头像、简介等注册信息存在违法和不良信息的，方正电子有限公司有权不经通知单方采取限期改正、暂停使用、注销登记、收回等措施。
          </p>
          <p>
            5.基于国家法律法规的规定要求或者方正电子有限公司服务政策的要求，您在使用某些方正电子服务时需要填写真实的姓名、身份证号等信息。若您填写的信息不完整、不正确，则可能无法使用相应服务或在使用过程中受到限制。
          </p>
          <p>
            6.当您使用刷脸登录、验证功能时，我们会收集并保存您的人脸照片和信息。您应按照页面的提示完成相应动作，方正电子学术期刊平台系统判断您的动作符合要求后，即可完成脸部拍摄。您同意仅限您本人为您本人所持有的方正电子学术期刊帐户使用刷脸登录、验证服务。
          </p>
          <p>
            7.方正电子学术期刊帐号包括帐户名称和密码，您可使用设置的帐户名称（包括用户名、手机号、邮箱）和密码登录；在帐号使用过程中，为了保障您的帐号安全基于不同的终端以及您的使用习惯，我们可能采取不同的验证措施识别您的身份。例如您的帐户在新设备首次登录，我们可能通过密码加校验码的方式识别您的身份，验证方式包括但不限于短信验证码、服务使用信息验证。
          </p>
          <p>
            8.用户不应将其帐号、密码转让、出售或出借予他人使用，帐号使用权仅属于初始申请注册人，用户应对帐号内的全部行为独立承担全部责任。如因帐号转让、买卖或出借导致的帐号纠纷或方正电子无法判断帐号归属等情况，方正电子有权拒绝提供帐号找回，并视情况采取冻结帐号、封禁帐号等措施。
          </p>
          <p>
            9.因您个人原因导致的帐号无法使用，如需找回方正电子学术期刊帐号，请按照方正电子学术期刊帐号找回要求提供相应的信息，并确保提供的信息合法真实有效，若提供的信息不符合要求或帐号的唯一凭证不再有效，无法通过方正电子有限责任公司安全验证，因存在帐号被盗等安全风险导致无法判断帐号归属，方正电子有限责任公司有权拒绝提供帐号找回服务，并视情况采取冻结帐号、封禁帐号等措施。您方正电子学术期刊帐号下绑定的手机号如因您个人原因停止使用，请尽快更改方正电子学术期刊帐号的绑定手机，否则如遇运营商二次放号，二次放号的用户可以通过您原来的手机号进行验证登录，可查看到您的全部帐号信息及产品使用信息，您的方正电子学术期刊帐号将无法继续使用，帐号内个人信息也有泄露的风险，请您务必及时更换绑定手机，如因未及时换绑手机号导致帐号丢失或个人信息泄露的责任由您自行承担，方正电子有限责任公司有权拒绝提供帐号找回服务，并视情况采取冻结帐号、封禁帐号等措施。
          </p>
          <p>
            10.在需要终止使用方正电子学术期刊帐号服务时，符合以下条件的，您可以申请注销您的方正电子学术期刊帐号。
          </p>
          <p>
            （1）您仅能申请注销您本人的帐号，并依照方正电子有限责任公司的流程进行注销；
          </p>
          <p>
            （2）您仍应对您在注销帐号前且使用方正电子有限责任公司服务期间的行为承担相应责任，同时方正电子有限责任公司仍可保存您注销前的相关信息；
          </p>
          <p>
            （3）注销成功后，帐号信息、个人身份证信息、交易记录、会员权益等将无法恢复或提供；
          </p>
          <p>
            11.为了防止资源占用，如您连续六个月未使用您的方正电子学术期刊帐号或未通过方正电子有限责任公司认可的其他方式登录过您的方正电子学术期刊帐号，方正电子有限责任公司有权对该帐户进行注销，您将不能再通过该帐号登录名登录本网站或使用相关服务。
          </p>
          <p>
            12.我们将按照方正电子有限责任公司的隐私权保护声明使用您在注册方正电子学术期刊帐号、使用方正电子学术期刊服务中提交、留存的信息。您保证在您使用方正电子有限责任公司的所有产品和服务前，已经充分了解并同意方正电子有限责任公司可以据此处理用户信息。
          </p>
          <p>
            13.为了满足相关法律法规的要求和保障您的帐号安全，尤其是您在进行帐号解绑、注销等敏感操作时，我们会将您提交的个人身份信息发送至银行、运营商等机构进行验证查询，以核实您身份的真实性。
          </p>
        </div>
      </div>
      <div class="agree__body__detail">
        <p class="agree__body__detail--title">三、服务内容</p>
        <div class="agree__body__detail--body">
          <p>
            1.除非本服务协议另有其它明示规定，方正电子学术期刊平台所推出的新产品、新功能、新服务，均受到本服务协议之规范。
          </p>
          <p>
            2.为使用本服务，您必须能够自行经有法律资格对您提供互联网接入服务的第三方，进入国际互联网，并应自行支付相关服务费用。此外，您必须自行配备及负责与国际联网连线所需之一切必要装备，包括计算机、数据机或其它存取装置。
          </p>
          <p>
            3.鉴于网络服务的特殊性，用户同意方正电子有限责任公司有权不经事先通知，随时变更、中断或终止部分或全部的网络服务（包括收费网络服务）。方正电子有限责任公司不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。
          </p>
          <p>
            4.免责声明：因以下情况造成网络服务在合理时间内的中断，方正电子有限责任公司无需为此承担任何责任；
          </p>
          <p>
            （1）方正电子有限责任公司需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，方正电子有限责任公司保留不经事先通知为维修保养、升级或其它目的暂停本服务任何部分的权利。
          </p>
          <p>（2）因台风、地震、洪水、雷电或恐怖袭击等不可抗力原因；</p>
          <p>（3）用户的电脑软硬件和通信线路、供电线路出现故障的；</p>
          <p>
            （4）因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因、第三方服务瑕疵或政府行为等原因。
          </p>
          尽管有前款约定，方正电子有限责任公司将采取合理行动积极促使服务恢复正常。
          <p>
            5.本服务或第三人可提供与其它国际互联网上之网站或资源之链接。由于方正电子有限责任公司无法控制这些网站及资源，您了解并同意，此类网站或资源是否可供利用，方正电子有限责任公司不予负责，存在或源于此类网站或资源之任何内容、广告、产品或其它资料，方正电子有限责任公司亦不予保证或负责。因使用或依赖任何此类网站或资源发布的或经由此类网站或资源获得的任何内容、商品或服务所产生的任何损害或损失，方正电子有限责任公司不承担任何责任。
          </p>
          <p>
            6.用户明确同意其使用方正电子有限责任公司网络服务所存在的风险将完全由其自己承担。用户理解并接受下载或通过方正电子有限责任公司服务取得的任何信息资料取决于用户自己，并由其承担系统受损、资料丢失以及其它任何风险。方正电子有限责任公司对在服务网上得到的任何商品购物服务、交易进程，都不作担保。
          </p>
          <p>
            7.用户须知：方正电子有限责任公司提供的各种挖掘推送服务中，推送给用户曾经访问过的网站或资源之链接是基于机器算法自动推出，方正电子有限责任公司不对其内容的有效性、安全性、合法性等做任何担保。
          </p>
          <p>
            8.方正电子有限责任公司有权于任何时间暂时或永久修改或终止本服务（或其任何部分），而无论其通知与否，方正电子有限责任公司对用户和任何第三人均无需承担任何责任。
          </p>
          9. 终止服务
          <p>
            您同意方正电子有限责任公司得基于其自行之考虑，因任何理由，包含但不限于长时间（超过一年）未使用，或方正电子有限责任公司认为您已经违反本服务协议的文字及精神，终止您的密码、帐号或本服务之使用（或服务之任何部分），并将您在本服务内任何内容加以移除并删除。您同意依本服务协议任何规定提供之本服务，无需进行事先通知即可中断或终止，您承认并同意，方正电子有限责任公司可立即关闭或删除您的帐号及您帐号中所有相关信息及文件，及/或禁止继续使用前述文件或本服务。此外，您同意若本服务之使用被中断或终止或您的帐号及相关信息和文件被关闭或删除，方正电子有限责任公司对您或任何第三人均不承担任何责任。
          </p>
        </div>
      </div>
      <div class="agree__body__detail">
        <p class="agree__body__detail--title">四、其他</p>
        <div class="agree__body__detail--body">
          <p>
            1. 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。
          </p>
          <p>
            2.如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向方正电子有限责任公司所在地北京市海淀区人民法院提起诉讼。
          </p>
          <p>
            3.方正电子有限责任公司未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。
          </p>
          <p>
            4.如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。
          </p>
          <p>
            5.欢迎您使用方正电子学术期刊产品旗下各类产品服务，并在本协议基础上，同时遵守单项服务协议的具体约定。
          </p>
          <p>
            6.当您通过方正电子学术期刊帐号登录、使用第三方产品或服务时，在遵守本协议基础上，您同时需要遵守该第三方产品或服务的具体使用规则，
          </p>
          <p>
            请您在发现任何违反本服务协议之情形时，通知方正电子有限责任公司。您可以通过如下联络方式同方正联系：
          </p>
          PC端：【在线客服】
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    computed: {},
    mounted() {},
    methods: {
      agree() {
        this.$router.push('/enterprise')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .emterprise {
    .el-button {
      margin-bottom: 14px;
    }
  }
</style>
<style lang="scss" scoped>
  .agree {
    font-family: Microsoft YaHei;
    &__top {
      height: 60px;
      background: #4054af;
      line-height: 60px;
      color: #ffffff;
      div {
        width: 800px;
        margin: 0 auto;
      }
    }
    &__body {
      width: 800px;
      margin: 0 auto;
      padding-bottom: 50px;
      &__top {
        font-size: 14px;
        background: #dee0ea;
        border-left: 2px solid #4054af;
        height: 30px;
        line-height: 30px;
        padding-left: 10px;
        font-weight: 500;
        margin: 20px 0 30px 0;
      }
      &__prompt__title,
      &__prompt__body {
        font-weight: 500;
        font-size: 14px;
      }
      &__prompt__body {
        margin-bottom: 30px;
      }
      &__detail {
        margin-bottom: 20px;
        font-size: 14px;
        &--title {
          font-weight: 500;
          margin-bottom: 10px;
          font-size: 14px;
        }
      }
    }
  }
</style>
